import React, { useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

const Navbar = () => {

    const [nav, setNav] = useState(false)

    const handleNav = ()=>{
        setNav(!nav)
    }
    return (
        <div className='flex justify-between px-4 items-center h-15 w-full mx-auto text-white sticky bg-[#000300] top-0 '>
            <h1 className='w-full p-4 md:p-0 font-bold text-3xl text-[#00df9a]'>REACT.</h1>

            <ul className='hidden md:flex'>
                <li className='p-4'>Home</li>
                <li className='p-4'>Company</li>
                <li className='p-4'>Resources</li>
                <li className='p-4'>About</li>
                <li className='p-4'>Contact</li>
            </ul>

            <div onClick={handleNav} className='block mx-4 md:hidden'>
                {!nav ? <AiOutlineClose size={20}/>:<AiOutlineMenu size={20} />}
            </div>

            <div className={!nav? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 md:hidden': 'hidden'}>
                <h1 className='w-full font-bold text-3xl text-[#00df9a] m-4'>REACT.</h1>

                <ul className='uppercase'>
                    <li className='p-4 border-b border-gray-600'>Home</li>
                    <li className='p-4  border-b border-gray-600'>Company</li>
                    <li className='p-4  border-b border-gray-600'>Resources</li>
                    <li className='p-4  border-b border-gray-600'>About</li>
                    <li className='p-4'>Contact</li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar